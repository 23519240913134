import Accordions from './components/accordion'
import Cards from './components/card'
import Dialogs from './components/dialog'
import Dropdowns from './components/dropdown'
import Navs from './components/nav'

import CountUp from './components/countUp'

// import SplideAboutHistory from './components/splide/splideAboutHistory'
// import SplideFeaturedArticles from './components/splide/splideFeaturedArticles'

console.info('%c🔥CK Started!', 'color: red; font-size: 2rem;')

// accessibility enhancements
Accordions()
Cards()
Dialogs()
Dropdowns()
Navs()

// gsap
CountUp()

// splidejs
// SplideAboutHistory('[data-splide="about-history"]')
// SplideFeaturedArticles('[data-splide="featured-articles"]')